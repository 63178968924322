<template>
  <section class="container pb-8">
    <div class="exam">
      <div class="exam__content">
        <div id="examMain" class="exam-main">
          <div class="exam-main__title">第 {{ currentTopic.seq }} 題</div>
          <div class="exam-main__content">
            <div class="exam-question">
              <div class="exam-question__tool">
                <span>{{ currentTopic.questionDifficultyName }}</span>
                <span>{{ currentTopic.questionTypeName }}</span>
                <a @click="handleOpenFeedBack()">
                  <i class="fa fa-bug" />
                </a>
              </div>

              <div class="exam-question__title">
                <p>{{ currentTopic.questionName }}</p>
              </div>

              <div class="exam-question__knowledge">
                <template v-for="item in currentTopic.knowledges">
                  <p :key="item.knowledgeId">{{ item.knowledgeName }}</p>
                </template>
              </div>

              <div class="exam-question__list">
                <template v-for="(item, index) in currentTopic.answers">
                  <el-checkbox
                    v-if="item.answerName !== ''"
                    v-model="item.isAnswer"
                    :key="item.answerId"
                    :true-label="1"
                    :false-label="0"
                    :disabled="currentTopic.isTrueAnswer !== null"
                    class="exam-question__option"
                    :class="{
                      'exam-question__option--error': isErrorAnswer(item),
                      'exam-question__option--true': isTrueAnswer(item) || isCorrectAnswer(item),
                    }"
                    @change="changeAnswer(item)"
                  >
                    <template v-if="currentTopic.isTrueAnswer !== null">
                      <span v-if="isTrueAnswer(item)">(正解)</span>
                      <i
                        v-if="isCorrectAnswer(item)"
                        class="fa fa-check-circle"
                        :style="{ color: '#62df68 ' }"
                      />
                      <i
                        v-if="isErrorAnswer(item)"
                        class="fa fa-times-circle"
                        :style="{ color: '#ff6363' }"
                      />
                    </template>

                    {{ getTitle(index) }} {{ item.answerName }}
                  </el-checkbox>
                </template>
              </div>

              <div
                v-if="currentTopic.isTrueAnswer !== null"
                class="exam-explain"
              >
                <div
                  class="exam-explain__head"
                  @click="explainShow = !explainShow"
                >
                  <div>
                    <i
                      class="el-alert__icon"
                      :class="{
                        'el-icon-info': !currentTopic.questionsNote,
                        'el-icon-circle-plus': currentTopic.questionsNote && !explainShow,
                        'el-icon-remove': currentTopic.questionsNote && explainShow,
                      }"
                    />

                    <span>
                      {{ currentTopic.questionsNote ? '查看詳解' : '暫無詳解' }}
                    </span>

                    <font v-if="currentTopic.questionAuthor">
                      - {{ currentTopic.questionAuthor }}
                    </font>
                  </div>

                  <span v-if="getCorrectOfTotal">
                    {{ getCorrectOfTotal }}
                  </span>
                </div>

                <div
                  v-if="explainShow && currentTopic.questionsNote"
                  class="exam-explain__content"
                  v-html="currentTopic.questionsNote"
                />
              </div>

              <div class="exam-question__buttonBox">
                <a
                  v-if="questionIndex !== 0"
                  class="exam-question__prev btn btn-text"
                  @click="handlePrevTopic"
                >
                  <span>上一題</span>
                </a>

                <a
                  v-if="currentTopic.isTrueAnswer !== null"
                  class="exam-question__reset btn btn-warning"
                  @click="handleReset()"
                >
                  <span>重新練習</span>
                </a>

                <a
                  v-if="
                    data.questions
                    && currentTopic.isTrueAnswer !== null
                    && questionIndex < data.questions.length - 1
                  "
                  class="exam-question__next btn btn-text"
                  @click="handleNextTopic()"
                >
                  <span>下一題</span>
                </a>

                <a
                  v-if="currentTopic.isTrueAnswer === null"
                  class="exam-question__submit btn btn-primary"
                  @click="handleToAnswer()"
                >
                  <span>送出</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="exam-list">
          <div class="exam-list__title">題目列表</div>
          <div class="exam-list__tabs">
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 1 }"
              @click="courseType = 1"
            >
              <i class="fa fa-book mr-2" aria-hidden="true" />
              <span>題目列表</span>
            </a>
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 2 }"
              @click="courseType = 2"
            >
              <i class="fa fa-paperclip mr-2" aria-hidden="true" />
              <span>錯題本</span>
            </a>
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 3 }"
              @click="courseType = 3"
            >
              <i class="fa fa-tags mr-2" aria-hidden="true" />
              <span>我的書籤</span>
            </a>
          </div>

          <div
            v-if="questionsData.length"
            class="exam-list__summary"
          >
            <i class="fa fa-th" aria-hidden="true" />
            <span>{{ summaryProcess }}</span>
            <span>{{ summaryRate }}</span>
          </div>

          <div class="exam-list__content">
            <template v-if="questionsData.length">
              <div
                v-for="(item, index) in questionsData"
                :key="`${index}-${item.questionCode}`"
                class="exam-list__item"
                :class="{'exam-list__item--active': item.seq - 1 === questionIndex}"
              >
                <a
                  class="exam-list__item-text"
                  @click="handleShowTopic(item, item.seq - 1)"
                >
                  <i
                    v-if="item.isTrueAnswer === false"
                    class="fa fa-times-circle"
                    style="color: red;"
                  />

                  <i
                    v-else
                    class="fa fa-check-circle"
                    :style="{ color: item.isTrueAnswer ? '#cccf2d' : '#c0c4cc' }"
                  />

                  <span>第 {{ item.seq }} 題</span>
                </a>

                <div class="exam-list__item-button">
                  <a @click="handleOpenNode(item, index)">
                    <span>筆記({{ item.recordNoteCount }})</span>
                  </a>

                  <a @click="handleFavorite(index, item)">
                    <i
                      class="fa fa-tag"
                      :style="{ color: item.isFavorite ? '#ff6363' : '#c0c4cc' }"
                    />
                  </a>
                </div>
              </div>
            </template>

            <p v-else>暫無資料</p>
          </div>

          <div class="exam-list__buttonBox">
            <a
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              @click="handleContinue()"
            >
              繼續練習
            </a>

            <a
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              @click="handleOpenNode()"
            >
              +筆記
            </a>
          </div>
        </div>
      </div>
    </div>

    <Notes
      :showModel.sync="showNote"
      :currentTopic="currentTopic"
      :category="data.categoryName"
      @changeNoteCount="changeNoteCount"
    />

    <FeedBack
      :show.sync="feedBackShow"
      :subject-name="data.subjectName"
      :question-code="questionCode"
    />
  </section>
</template>

<script>
import { GetPracticeCategoryByMid, ResponderPracticeByMid } from '@/api/BuyPracticeApi';
import { AddToFavoritePractice, RemoveToFavoritePractice } from '@/api/MemberPracticeRecordApi';

import Notes from '@/components/Practice/Notes.vue';
import FeedBack from '@/components/Practice/FeedBack.vue';
// import CourseInfon from '@/components/CourseInfon.vue';

/* global $ */
export default {
  props: ['currentPage'],
  components: {
    // CourseInfon,
    Notes,
    FeedBack,
  },
  data() {
    return {
      courseType: 1,
      showNote: 0,
      questionCode: '',
      feedBackShow: false,
      showTopic: false,
      data: {},
      currentTopic: {
        isFavorite: false,
        isMarkAnswer: false,
        isTrueAnswer: false,
        questionCode: '',
        questionId: 0,
        questionName: '',
        questionType: 0,
        questionTypeName: '',
        questionsNote: '',
        recordNoteCount: 0,
        answers: [],
      },

      questionIndex: 0,
      explainShow: false,

      isSubmitting: false,
    };
  },
  created() {
    this.GetPracticeCategoryByMid();
  },
  mounted() {
  },
  computed: {
    summaryProcess() {
      let questionTotal = 0;

      if (!this._.isEmpty(this.data.questions)) {
        const data = this._.filter(this.data.questions, (item) => item.responderAnswers.length > 0);
        questionTotal = data.length;
      }

      return `進度：${questionTotal}/${this.data.questions.length}`;
    },
    summaryRate() {
      return `錯誤率：${this.data.questionIncorrect}/${this.data.questions.length}`;
    },
    questionsData() {
      const tmpQuestions = this._.filter(this.data.questions,
        (item) => this.courseType === 1
          || (this.courseType === 2 && item.isTrueAnswer === false)
          || (this.courseType === 3 && item.isFavorite));

      return tmpQuestions;
    },
    getCorrectOfTotal() {
      const { responderCorrect, responderTotal } = this.currentTopic;

      if (responderCorrect && responderTotal) {
        return `考古題成績答對率：${responderCorrect}/${responderTotal} (人)`;
      }

      return '';
    },
  },
  methods: {
    // 取得所有題目
    GetPracticeCategoryByMid() {
      const { id } = this.$route.params;
      const postData = {
        categoryId: id,
        practiceShowType: 1,
      };
      GetPracticeCategoryByMid(postData).then((response) => {
        if (response.data.statusCode === 0) {
          try {
            this.data = this._.cloneDeep(response.data.data);

            this._.forEach(this.data.questions, (item) => {
              this._.sortBy(item.answers, ['answerId']);
            });

            this.$emit('update:currentPage', [
              {
                name: 'practicesCategory',
                title: `${this.data.subjectName}`,
                path: `/practices/${this.data.subjectId}`,
              },
              {
                name: 'exercise',
                title: this.data.categoryName,
                path: '',
              },
            ]);

            this.handleShowTopic(this.data.questions[0], 0);
          } catch (error) {
            this.$router.replace('/error').catch();
          }
        } else this.$router.replace('/error').catch();
      });
    },
    // 顯示選擇的題目在畫面上
    handleShowTopic(topic, index) {
      this.showTopic = true;
      // 清空作答
      this.currentTopic = this._.cloneDeep(topic);

      if (this.currentTopic.isTrueAnswer === null) {
        this._.map(this.currentTopic.answers, (item) => {
          // eslint-disable-next-line no-param-reassign
          item.isAnswer = 0;
          return item;
        });
      }

      this.questionIndex = index;
      this.explainShow = false;

      this.$nextTick(() => {
        $('.exam-list__content').animate({
          scrollTop: 48 * this.questionIndex,
        }, 0);

        if ($(window).width() < 992) {
          $('html, body').animate({
            scrollTop: $('#examMain').offset().top - 62,
          }, 0);
        }
      });
    },
    handleToAnswer() {
      if (this.isSubmitting) {
        return;
      }

      if (this._.filter(this.currentTopic.answers, (item) => item.isAnswer === 1).length === 0) {
        this.$message({
          type: 'error',
          message: '請至少選擇一項答案',
        });

        return;
      }

      this.isSubmitting = true;

      const { questionId, seq } = this.currentTopic;

      let responderAnswers = this._.map(this.currentTopic.answers, (item) => {
        if (item.isAnswer === 1) {
          return item.answerId;
        }

        return 0;
      });

      responderAnswers = this._.filter(responderAnswers, (item) => item !== 0);

      const postData = {
        questionId,
        responderAnswers,
      };
      ResponderPracticeByMid(postData).then((response) => {
        if (response.data.statusCode === 0) {
          const question = response.data.data;
          const questionIndex = this._.findIndex(this.data.questions, ['questionId', question.questionId]);
          question.seq = seq;

          this.$set(this.data.questions, questionIndex, question);

          if (question.isTrueAnswer === false) {
            this.data.questionIncorrect += 1;
          }

          this.currentTopic = this._.cloneDeep(question);
        } else if (response.data.statusCode === 821016) {
          this.$message({
            type: 'error',
            message: response.message,
          });
        } else this.$router.replace('/error').catch();

        this.isSubmitting = false;
      });
    },
    // 開啟筆記
    handleOpenNode(topic = null, index = 0) {
      if (topic === null && this.currentTopic.questionId === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '請先選擇要做筆記的題目',
        });
        return;
      }

      if (topic !== null) {
        this.handleShowTopic(topic, index);
      }
      this.showNote = 1;
    },
    // 同步筆記視窗的筆記數量
    changeNoteCount(count) {
      // 取得原始資料進行設定(就不在呼叫API取得資料)
      this._.find(this.data.questions,
        { questionId: this.currentTopic.questionId })
        .recordNoteCount = count;
    },
    // 我的書籤
    handleFavorite(index, item) {
      const { isFavorite, questionId } = item;
      let func = AddToFavoritePractice;
      if (isFavorite) {
        func = RemoveToFavoritePractice;
      }
      func({ questionId }).then((res) => {
        if (res.data.statusCode === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: `成功將第 ${index + 1} 題${isFavorite ? '移除' : '加入'}我的書籤`,
          });

          // 取得原始資料進行設定(就不在呼叫API取得資料)
          this._.find(this.data.questions, { questionId }).isFavorite = !isFavorite;
        }
      });
    },
    changeAnswer(answerItem) {
      if (
        this.currentTopic.isTrueAnswer === null
        && this.currentTopic.questionType === 1
        && answerItem.isAnswer === 1
      ) {
        this._.forEach(this.currentTopic.answers, (item) => {
          if (item.answerId !== answerItem.answerId) {
            // eslint-disable-next-line no-param-reassign
            item.isAnswer = 0;
          }
        });
      }
    },
    handlePrevTopic() {
      const index = this.data.questions[this.questionIndex - 1].seq - 1;
      const topic = this._.cloneDeep(this.data.questions[this.questionIndex - 1]);

      this.handleShowTopic(topic, index);
    },
    handleNextTopic() {
      const index = this.data.questions[this.questionIndex + 1].seq - 1;
      const topic = this._.cloneDeep(this.data.questions[this.questionIndex + 1]);

      this.handleShowTopic(topic, index);
    },
    isErrorAnswer(item) {
      if (this.currentTopic.responderAnswers.length === 0) {
        return false;
      }

      return item.isAnswer !== 1
        && this._.includes(this.currentTopic.responderAnswers, item.answerId);
    },
    isTrueAnswer(item) {
      if (this.currentTopic.responderAnswers.length === 0) {
        return false;
      }

      return item.isAnswer === 1
        && !this._.includes(this.currentTopic.responderAnswers, item.answerId);
    },
    isCorrectAnswer(item) {
      if (this.currentTopic.responderAnswers.length === 0) {
        return false;
      }

      return item.isAnswer === 1
        && this._.includes(this.currentTopic.responderAnswers, item.answerId);
    },
    handleContinue() {
      const index = this._.findIndex(this.data.questions, (item) => item.isTrueAnswer === null);
      const topic = this._.cloneDeep(this.data.questions[index]);

      this.handleShowTopic(topic, index);
    },
    handleReset() {
      if (this.currentTopic.isTrueAnswer === false) {
        this.data.questionIncorrect -= 1;
      }

      const topic = this._.find(this.questionsData, ['questionId', this.currentTopic.questionId]);

      topic.responderAnswers = [];
      topic.isTrueAnswer = null;

      this._.forEach(topic.answers, (item) => {
        // eslint-disable-next-line no-param-reassign
        item.isAnswer = 0;
      });

      this.currentTopic.responderAnswers = [];
      this.currentTopic.isTrueAnswer = null;
      this._.forEach(this.currentTopic.answers, (item) => {
        // eslint-disable-next-line no-param-reassign
        item.isAnswer = 0;
      });
    },
    handleOpenFeedBack() {
      this.questionCode = this.currentTopic.questionCode;
      this.feedBackShow = true;
    },
    getTitle(index) {
      const title = ['(A)', '(B)', '(C)', '(D)'];

      return title[index];
    },
  },
};
</script>

<style lang="scss" scoped>
// none
</style>

<template>
  <div ref="nodteDiv" class="noteSidenav">
    <a href="#" class="noteClosebtn" @click.prevent="handleClose()">&times;</a>
    <div>
      <h4 class="text-center">{{ categoryName }}</h4>
      <p class="text-center">{{ currentTopic.questionName }}</p>

      <template v-if="!showEdit">
        <a class="btn btn-hover-primary text-white border-right mb-2 ml-2" @click="handleAddNote()"
          >+筆記</a
        >
        <div class="">
          <ul class="cs-table" data-style="11">
            <li class="table__head d-none d-xl-flex">
              <div class="table__head-td">筆記標題</div>
              <div class="table__head-td">更新時間</div>
              <div class="table__head-td">操作</div>
            </li>
            <li
              class="table__item"
              data-toggle="modal"
              data-target="#memberMsg"
              v-for="(item, index) in noteList"
              :key="item.id"
            >
              <div class="table__item-td table__item--noteTitle">
                {{ item.recordNoteTitle }}
              </div>
              <div class="table__item-td table__item--modifyDate">
                {{ item.modifyDate | toTaipei | moment('YYYY/MM/DD HH:mm') }}
              </div>
              <div class="table__item-td table__item--action">
                <button class="btn btn-primary btn-sm mr-2" @click="handleAddNote(index)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button class="btn btn-danger btn-sm" @click="handleDelNote(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <el-form
          style="width: 100%;"
          :model="userNoteForm"
          label-position="top"
          :rules="userNoteRules"
          ref="userNoteForm"
          class="cs-form"
          data-style="2"
          @submit.prevent.native
        >
          <!-- 標題摘要 -->
          <div class="form-group ">
            <label class="col-12 col-form-label necessary" for="recordNoteTitle">
              標題摘要
            </label>
            <el-form-item prop="recordNoteTitle" class="col-12">
              <el-input
                :class="`el-input__input`"
                placeholder="標題摘要"
                type="text"
                id="recordNoteTitle"
                v-model="userNoteForm.recordNoteTitle"
              />
            </el-form-item>
          </div>

          <!-- 筆記內容 -->
          <div class="form-group ">
            <label class="col-12 col-form-label necessary" for="recordNoteTitle">
              筆記內容
            </label>
            <div class="col-12">
              <textarea
                v-model="userNoteForm.recordNoteContent"
                class="form-control"
                style="resize:none;height:400px;"
              >
              </textarea>
            </div>
            <el-form-item prop="recordNoteContent" class="col-12"> </el-form-item>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group mb-6 mb-md-8 text-right">
            <button
              type="button"
              class="btn btn-outline-secondary btn-lg border-radius-l mr-2"
              @click="showEdit = false"
            >
              <span class="small">返回列表</span>
            </button>
            <button
              type="button"
              class="btn btn-hover-primary text-white btn-lg border-radius-l mr-2"
              @click="handleSaveNote"
            >
              <span class="small">儲存筆記</span>
            </button>
          </div>
        </el-form>
      </template>
    </div>
  </div>
</template>

<script>
import {
  GetRecordPracticeNotes,
  DeleteRecordPracticeNoteById,
  SaveRecordPracticeNoteById,
  GetRecordPracticeNoteById,
} from '@/api/MemberPracticeRecordApi';

export default {
  // 筆記清單、顯示筆記列表、
  props: ['showModel', 'currentTopic', 'category'],
  data() {
    return {
      // 筆記表單
      userNoteForm: {
        id: 0, // int:編號,新增=0
        questionId: '', // 題目代號
        recordNoteTitle: '', // string:標題(20字)
        recordNoteContent: '', // string:html內文(不限制)
      },
      // 檢查輸入的值規則
      userNoteRules: {
        recordNoteTitle: [
          { required: true, message: '請輸入標題摘要', trigger: 'blur' },
          { max: 20, message: '標題摘要不能超過20個字' },
        ],
        recordNoteContent: [{ required: true, message: '請輸入筆記內容', trigger: 'blur' }],
      },
      // 筆記清單
      noteList: [],
      // 顯示筆記視窗 (props)
      nodeOpenList: this.showModel,
      // 課程名稱 (props)
      categoryName: this.category,
      // 編輯視窗
      showEdit: false,
      // 編輯中的筆記index
      modifyNoteIndex: -1,
    };
  },
  methods: {
    // 關閉筆記列表
    handleClose() {
      this.showEdit = false;
      this.$emit('update:showModel', 0);
    },
    // 新增筆記
    handleAddNote(index = -1) {
      const { questionId } = this.currentTopic;
      this.modifyNoteIndex = -1;
      this.userNoteForm.id = 0;
      this.userNoteForm.questionId = questionId;
      this.userNoteForm.recordNoteTitle = '';
      this.userNoteForm.recordNoteContent = '';
      if (index >= 0) {
        this.handleGetRecordNote(this.noteList[index].id);
        this.modifyNoteIndex = index;
      }
      this.showEdit = true;
    },
    // 刪除筆記
    handleDelNote(index) {
      const { id, recordNoteTitle } = this.noteList[index];
      this.$confirm(`確定要將[${recordNoteTitle}]刪除 ?`, '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          DeleteRecordPracticeNoteById({ id }).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '成功刪除筆記',
              });
              this.noteList.splice(index, 1);
              this.$emit('changeNoteCount', this.noteList.length);
            } else this.$router.replace('/error').catch();
          });
        })
        .catch(() => {});
    },
    // 儲存筆記
    handleSaveNote() {
      this.$refs.userNoteForm.validate((valid) => {
        if (valid) {
          SaveRecordPracticeNoteById(this.userNoteForm).then((response) => {
            if (response.data.statusCode === 0) {
              const msg = `成功${this.userNoteForm.id === 0 ? '新增' : '修改'}筆記`;
              if (this.userNoteForm.id === 0) {
                this.noteList.push({ ...response.data.data });
                this.userNoteForm.id = response.data.data.id;
                this.modifyNoteIndex = this.noteList.length - 1;
              } else {
                this.noteList.splice(this.modifyNoteIndex, 1, { ...response.data.data });
              }
              this.noteList = this._.sortBy(this.noteList, ['sort']);
              this.$message({
                showClose: true,
                type: 'success',
                message: msg,
              });
              this.$emit('changeNoteCount', this.noteList.length);
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
    // 取得筆記列表
    async handleGetRecordNotes() {
      const { questionId } = this.currentTopic;
      const postData = {
        pageIndex: 1,
        pageSize: 9999,
        data: {
          questionId,
        },
      };
      await GetRecordPracticeNotes(postData).then((response) => {
        if (response.data.statusCode === 0) {
          const { data } = response.data;
          this.noteList = data;
        }
      });
    },
    // 取得筆記 by id
    handleGetRecordNote(id) {
      GetRecordPracticeNoteById({ id }).then((response) => {
        if (response.data.statusCode === 0) {
          this.userNoteForm = { ...response.data.data };
        }
      });
    },
  },
  watch: {
    category(val) {
      this.categoryName = val;
    },
    showModel(val) {
      this.nodeOpenList = val;
    },
    async nodeOpenList(val) {
      if (val > 0) {
        if (this.className === '') {
          this.$message({
            showClose: true,
            type: 'warning',
            message: '請先選擇課堂',
          });
          this.$emit('update:showModel', 0);
        } else {
          await this.handleGetRecordNotes();
          this.$refs.nodteDiv.classList.add('noteSidenavOpen');
          if (val > 1) this.handleAddNote();
          else this.showEdit = false;
        }
      } else {
        this.$refs.nodteDiv.classList.remove('noteSidenavOpen');
        this.showEdit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ql-toolbar {
  background: white;
}
/deep/ .ql-editor {
  background: white;
}
</style>

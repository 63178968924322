import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** MemberPracticeRecord api */
/**
 * 加入我的最愛
 * @param {object} data { questionId:問題代號 }
 */
export const AddToFavoritePractice = function AddToFavoritePractice(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('FrontEnd/MemberPracticeRecord/AddToFavoritePractice', data);
};

/**
 * 取消我的最愛
 * @param {object} data { questionId:問題代號  }
 */
export const RemoveToFavoritePractice = function RemoveToFavoritePractice(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('FrontEnd/MemberPracticeRecord/RemoveToFavoritePractice', data);
};

/**
 * 取得筆記列表
 * @param {object} data
  {
    "pageIndex": 0,
    "pageSize": 0,
    "data": {
      "classCode": "string"
    }
  }
 */
export const GetRecordPracticeNotes = (data) => baseAxios.post('FrontEnd/MemberPracticeRecord/GetRecordPracticeNotes', data);

/**
 * 刪除筆記 BY id
 * @param {object} data { id }
 */
export const DeleteRecordPracticeNoteById = (data) => baseAxios.post('FrontEnd/MemberPracticeRecord/DeleteRecordPracticeNoteById', data);

/**
 * 儲存筆記 BY id
 * @param {object} data { id }
 */
export const SaveRecordPracticeNoteById = (data) => baseAxios.post('FrontEnd/MemberPracticeRecord/SaveRecordPracticeNoteById', data);

/**
 * 取得筆記 BY id
 * @param {object} data { id }
 */
export const GetRecordPracticeNoteById = (data) => baseAxios.post('FrontEnd/MemberPracticeRecord/GetRecordPracticeNoteById', data);
